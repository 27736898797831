// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  EmptyGUID: '00000000-0000-0000-0000-000000000000',
  SoftwareGUID: 'A63F5B33-1A91-490D-9B1F-84991FD89AA7',
  defaultLang: 'en',
  odataUrl: 'http://localhost:7777/odata/Setaregan',
  apiUrl: 'http://localhost:7777/api',
  apiVersion: 'v1',
  appVersion: 'v1.001.2',
  downloadURL: 'http://localhost:7777',
  homeRoot: '/Config/entity/Users',
  };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
