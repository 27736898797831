import { Component, OnInit } from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { UserInfo } from 'src/app/_models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	
	toggleChat: boolean = true;
	toggleSingle: boolean = true;
	Username = '';
	UserEmail= '';

	constructor() { }
	
	ngOnInit(): void {
		const currentUser = JSON.parse(String(localStorage.getItem('SetareganUserInfo')));
		if(currentUser){
			const user = currentUser as UserInfo;
			this.Username = user.Name;
			this.UserEmail = user.Email;
		}
	}
	
	
	togglechatbar() {
		this.toggleChat = !this.toggleChat;
	}
	singleChatWindow() {
		this.toggleSingle = !this.toggleSingle;
	}

}
