import { Injectable } from "@angular/core";
import { Subject } from "rxjs";


@Injectable({
  providedIn: 'root',
})
export class DailyReportHandlerService {

  constructor() { }

  //CurrentOffice
  private _CurrentOfficeGUID: string;
  SetCurrentOfficeGUID(CurrentOfficeGUID: string) {
    this._CurrentOfficeGUID = CurrentOfficeGUID;
  }
  GetCurrentOfficeGUID() {
    return this._CurrentOfficeGUID;
  }
  //CurrentOffice


  //CurrentSubOffice
  private _CurrentSubOfficeGUID: string;
  SetCurrentSubOfficeGUID(CurrentSubOfficeGUID: string) {
    this._CurrentSubOfficeGUID = CurrentSubOfficeGUID;
  }
  GetCurrentSubOfficeGUID() {
    return this._CurrentSubOfficeGUID;
  }
  //CurrentSubOffice


  //CurrentFlock
  private _CurrentFlockGUID: string;
  SetCurrentFlockGUID(CurrentFlockGUID: string) {
    this._CurrentFlockGUID = CurrentFlockGUID;
  }
  GetCurrentFlockGUID() {
    return this._CurrentFlockGUID;
  }
  //CurrentFlock


  //CurrentsDate
  _CurrentDate: string;
  SetCurrentDate(CurrentDate: string) {
    this._CurrentDate = CurrentDate;
  }
  GetCurrentDate() {
    return this._CurrentDate;
  }
  //CurrentsDate



  private _RefreshReport = new Subject();
  Observable_RefreshReport = this._RefreshReport.asObservable();
  RefreshReport(
    OfficeGUID: string
    , SubOfficeGUID: string
    , FlockGUID: string
    , Date: string
  ) {
    this.SetCurrentOfficeGUID(OfficeGUID);
    this.SetCurrentSubOfficeGUID(SubOfficeGUID);
    this.SetCurrentFlockGUID(FlockGUID);
    this.SetCurrentDate(Date);
    this._RefreshReport.next();
  }


  private _SelectReport = new Subject();
  Observable_SelectReport = this._SelectReport.asObservable();
  SelectReport(
    OfficeGUID: string
    , SubOfficeGUID: string
    , FlockGUID: string
    , Date: string
  ) {
    this.SetCurrentOfficeGUID(OfficeGUID);
    this.SetCurrentSubOfficeGUID(SubOfficeGUID);
    this.SetCurrentFlockGUID(FlockGUID);
    this.SetCurrentDate(Date);
    this._SelectReport.next();
  }
}
