<div class="authincation vh-100">
        <div class="container h-100">
            <div class="row justify-content-center h-100 align-items-center">
                <div class="col-md-8">
                    <div class="form-input-content text-center error-page">
                        <h1 class="error-text font-weight-bold">404</h1>
                        <h4><i class="fa fa-exclamation-triangle text-warning"></i> صفحه ای که دنبالش هستید یافت نشد!</h4>
                        <p>ممکن است ورژن شما قدیمی باشد، از ترکیب کلید Ctrl+F5 استفاده نمایید</p>
						<div>
                            <a class="btn btn-primary" [routerLink]="['/Config']">برگشت به صفحه اصلی</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>