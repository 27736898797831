import { environment } from 'src/environments/environment';
import { RepositoryService } from './../../_services/repository.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  public currentHref: string = "";
  public appVersion: string = "";

  constructor(protected location: Location,
    protected router: Router,
    protected RepositoryService: RepositoryService,) {
    this.router.events.subscribe((val) => {
      if (this.location.path() != '') {
        this.currentHref = '#' + this.location.path();
      } else {
        this.currentHref = 'Home'
      }
    });
  }
  async loadMenu() {
  }
  TagActiveMenu() {
  }
  async ngOnInit(): Promise<void> {
this.appVersion = environment.appVersion;
  }

  toggleIcon: boolean = true;

  toggleLoveIcon() {
    this.toggleIcon = !this.toggleIcon;
  }

  baseArray = [
    'base/PaymentTypes',
    '/base/AccountingTitleGroups',
    '/base/AccountingTitles',
    '/base/FileTypes',
    '/base/RequestTypes',
    '/base/RequestStatusTypes',
    '/base/ActivityTypes',
    '/base/AuditorTypes',
    '/base/CertificateTypes',
    '/base/CompanyAges',
    '/base/CompanyTypes',
    '/base/ContractCertificates',
    '/base/ContractCompanies',
    '/base/ContractFamilies',
    '/base/ContractManagerImmovables',
    '/base/ContractManagerInOtherCompanies',
    '/base/ContractManagers',
    '/base/ContractOwners',
    '/base/Contracts',
    '/base/CustomerRanks',
    '/base/ExpertOpinionTypes',
    '/base/ExpiredZemanatnamehTypes',
    '/base/HRStabilityTypes',
    '/base/ImmovableTypes',
    '/base/KnowledgeTypes',
    '/base/LastStatusTaahodPardakhtTypes',
    '/base/LastStatusZemanatTypes',
    '/base/LastTashilatTypes',
    '/base/LastZemanatTypes',
    '/base/ManagerResumeTypes',
    '/base/ManifestTypes',
    '/base/MasterStatusTashilatTypes',
    '/base/MasterStatusZemanatTypes',
    '/base/OwnerTypes',
    '/base/StartupTypes',
    '/base/StockTypes',
    '/base/TotalAccountingTitleRanges',
    '/base/TotalAccountingTitles',
    '/base/VasigheTypes',
    '/base/ZaribTypes',
  ];

  authbaseArray = [
    '/authbase/ComponentActions',
    '/authbase/Components',
    '/authbase/Roles',
    '/authbase/Softwares',
  ];

  Array = [
    '/entity/Users',
  ];

  humanresourceArray= [
    '/humanresource/Personnels',
    '/humanresource/PersonnelPosts',
  ];

  humanresourcebaseArray= [
    '/humanresource/Posts',
    '/humanresource/Genders',
    '/humanresource/SoldierTypes',
  ];

  bpmbaseArray=[
    '/bpmbase/Flows',
    '/bpmbase/TaskActions',
  ];

}


