<div class="deznav">
        <div class="deznav-scroll" [perfectScrollbar]>
                <ul class="metismenu" id="menu" metis-menu>



                        <li [ngClass]="{'mm-active': Array.indexOf(currentHref) > -1}">
                                <a [routerLink]="['entity/Users']"
                                        class="{{(currentHref == '/entity/Users') ? 'mm-active' : ''}}">
                                        <i class="flaticon-381-user-9"></i>
                                        <span class="nav-text"> کاربران</span>
                                </a>

                        </li>



                        <li [ngClass]="{'mm-active': humanresourceArray.indexOf(currentHref) > -1}">
                                <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                        <i class="flaticon-381-user-7" aria-hidden="true"></i>
                                        <span class="nav-text"> پرسنلی </span>
                                </a>


                                <ul aria-expanded="false"
                                        [ngClass]="{'mm-show': humanresourceArray.indexOf(currentHref) > -1}"
                                        style="z-index: 2000;">
                                        <li><a [routerLink]="['humanresource/Personnels']"
                                                        class="{{(currentHref == '/humanresource/Personnels') ? 'mm-active' : ''}}">
                                                        پرسنل</a>
                                        </li>

                                        <li><a [routerLink]="['humanresource/PersonnelPosts']"
                                                        class="{{(currentHref == '/humanresource/PersonnelPosts') ? 'mm-active' : ''}}">
                                                        پست پرسنل </a>
                                        </li>



                                </ul>
                        </li>



                        <li [ngClass]="{'mm-active': humanresourcebaseArray.indexOf(currentHref) > -1}">
                                <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                        <i class="fa fa-edit" aria-hidden="true"></i>
                                        <span class="nav-text">اطلاعات پایه پرسنلی</span>
                                </a>


                                <ul aria-expanded="false"
                                        [ngClass]="{'mm-show': humanresourcebaseArray.indexOf(currentHref) > -1}"
                                        style="z-index: 2000;">
                                        <li><a [routerLink]="['humanresourcebase/Posts']"
                                                        class="{{(currentHref == '/humanresourcebase/Posts') ? 'mm-active' : ''}}">
                                                        پست ها</a>
                                        </li>

                                        <li><a [routerLink]="['humanresourcebase/Genders']"
                                                        class="{{(currentHref == '/humanresourcebase/Genders') ? 'mm-active' : ''}}">
                                                        جنسیت</a>
                                        </li>
                                        <li><a [routerLink]="['humanresourcebase/SoldierTypes']"
                                                        class="{{(currentHref == '/humanresourcebase/SoldierTypes') ? 'mm-active' : ''}}">
                                                        نظام وظیفه </a>
                                        </li>



                                </ul>
                        </li>





                        <li [ngClass]="{'mm-active': bpmbaseArray.indexOf(currentHref) > -1}">
                                <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                        <i class="fa fa-edit" aria-hidden="true"></i>
                                        <span class="nav-text">اطلاعات پایه گردش کار</span>
                                </a>


                                <ul aria-expanded="false"
                                        [ngClass]="{'mm-show': bpmbaseArray.indexOf(currentHref) > -1}"
                                        style="z-index: 2000;">
                                        <li><a [routerLink]="['bpmbase/Flows']"
                                                        class="{{(currentHref == '/bpmbase/Flows') ? 'mm-active' : ''}}">
                                                        فرآیند</a>
                                        </li>

                                        <li><a [routerLink]="['bpmbase/TaskActions']"
                                                        class="{{(currentHref == '/bpmbase/TaskActions') ? 'mm-active' : ''}}">
                                                        عملیات فرآیند</a>
                                        </li>
                                    



                                </ul>
                        </li>




                        <li [ngClass]="{'mm-active': baseArray.indexOf(currentHref) > -1}">
                                <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                        <i class="fa fa-hashtag" aria-hidden="true"></i>
                                        <span class="nav-text">اطلاعات پایه</span>
                                </a>


                                <ul aria-expanded="false" [ngClass]="{'mm-show': baseArray.indexOf(currentHref) > -1}"
                                        style="z-index: 2000;">

                                        <li><a [routerLink]="['base/PaymentTypes']"
                                                        class="{{(currentHref == '/base/PaymentTypes') ? 'mm-active' : ''}}">
                                                        انواع پرداخت</a>
                                        </li>


                                        <li><a [routerLink]="['base/AccountingTitleGroups']"
                                                        class="{{(currentHref == '/base/accounting-title-groups') ? 'mm-active' : ''}}">گروه
                                                        سرفصل</a>
                                        </li>
                                        <li><a [routerLink]="['base/AccountingTitles']"
                                                        class="{{(currentHref == '/base/accounting-titles') ? 'mm-active' : ''}}">سر
                                                        فصل</a>
                                        </li>






                                        <li><a [routerLink]="['base/FileTypes']"
                                                        class="{{(currentHref == '/base/FileTypes') ? 'mm-active' : ''}}">
                                                        انواع فایل</a>
                                        </li>

                                        <li><a [routerLink]="['base/RequestTypes']"
                                                        class="{{(currentHref == '/base/RequestTypes') ? 'mm-active' : ''}}">
                                                        نوع درخواست</a>
                                        </li>

                                        <li><a [routerLink]="['base/RequestStatusTypes']"
                                                        class="{{(currentHref == '/base/RequestStatusTypes') ? 'mm-active' : ''}}">
                                                        وضعیت درخواست</a>
                                        </li>





                                        <li><a [routerLink]="['base/ActivityTypes']"
                                                        class="{{(currentHref == '/base/ActivityTypes') ? 'mm-active' : ''}}">نوع
                                                        فعالیت</a>
                                        </li>
                                        <li><a [routerLink]="['base/AuditorTypes']"
                                                        class="{{(currentHref == '/base/AuditorTypes') ? 'mm-active' : ''}}">نظر
                                                        حسابرس</a>
                                        </li>
                                        <li><a [routerLink]="['base/CertificateTypes']"
                                                        class="{{(currentHref == '/base/CertificateTypes') ? 'mm-active' : ''}}">مجوزها</a>
                                        </li>
                                        <li><a [routerLink]="['base/CompanyAges']"
                                                        class="{{(currentHref == '/base/CompanyAges') ? 'mm-active' : ''}}">قدمت
                                                        سازمان</a>
                                        </li>
                                        <li><a [routerLink]="['base/CompanyTypes']"
                                                        class="{{(currentHref == '/base/CompanyTypes') ? 'mm-active' : ''}}">نوع
                                                        شرکت</a>
                                        </li>

                                        <li><a [routerLink]="['base/CustomerRanks']"
                                                        class="{{(currentHref == '/base/CustomerRanks') ? 'mm-active' : ''}}">رتبه
                                                        مشتری</a>
                                        </li>
                                        <li><a [routerLink]="['base/ExpertOpinionTypes']"
                                                        class="{{(currentHref == '/base/ExpertOpinionTypes') ? 'mm-active' : ''}}">نظر
                                                        کارشناس ارشد</a>
                                        </li>
                                        <li><a [routerLink]="['base/ExpiredZemanatnamehTypes']"
                                                        class="{{(currentHref == '/base/ExpiredZemanatnamehTypes') ? 'mm-active' : ''}}">
                                                        ضمانت نامه های سر رسید شده </a>
                                        </li>
                                        <li><a [routerLink]="['base/HRStabilityTypes']"
                                                        class="{{(currentHref == '/base/HRStabilityTypes') ? 'mm-active' : ''}}">ثبات
                                                        نیروی انسانی تیم مدیریتی</a>
                                        </li>
                                        <li><a [routerLink]="['base/ImmovableTypes']"
                                                        class="{{(currentHref == '/base/immovable-types') ? 'mm-active' : ''}}">نوع
                                                        غیر منقول</a>
                                        </li>
                                        <li><a [routerLink]="['base/KnowledgeTypes']"
                                                        class="{{(currentHref == '/base/KnowledgeTypes') ? 'mm-active' : ''}}">
                                                        وضعیت دانش فنی شرکت</a>
                                        </li>
                                        <li><a [routerLink]="['base/LastStatusTaahodPardakhtTypes']"
                                                        class="{{(currentHref == '/base/LastStatusTaahodPardakhtTypes') ? 'mm-active' : ''}}">سابقه
                                                        تعهد پرداخت</a>
                                        </li>
                                        <li><a [routerLink]="['base/LastStatusZemanatTypes']"
                                                        class="{{(currentHref == '/base/LastStatusZemanatTypes') ? 'mm-active' : ''}}">سابقه
                                                        ضمانت نامه</a>
                                        </li>
                                        <li><a [routerLink]="['base/LastTashilatTypes']"
                                                        class="{{(currentHref == '/base/LastTashilatTypes') ? 'mm-active' : ''}}">سابقه
                                                        تسهیلات</a>
                                        </li>
                                        <li><a [routerLink]="['base/LastZemanatTypes']"
                                                        class="{{(currentHref == '/base/LastZemanatTypes') ? 'mm-active' : ''}}">سابقه
                                                        ضمانت نامه</a>
                                        </li>
                                        <li><a [routerLink]="['base/ManagerResumeTypes']"
                                                        class="{{(currentHref == '/base/ManagerResumeTypes') ? 'mm-active' : ''}}">رزومه
                                                        مدیر</a>
                                        </li>
                                        <li><a [routerLink]="['base/ManifestTypes']"
                                                        class="{{(currentHref == '/base/ManifestTypes') ? 'mm-active' : ''}}">وضعیت
                                                        اظهارنامه</a>
                                        </li>
                                        <li><a [routerLink]="['base/MasterStatusTashilatTypes']"
                                                        class="{{(currentHref == '/base/MasterStatusTashilatTypes') ? 'mm-active' : ''}}">سابقه
                                                        تسهیلات</a>
                                        </li>
                                        <li><a [routerLink]="['base/MasterStatusZemanatTypes']"
                                                        class="{{(currentHref == '/base/MasterStatusZemanatTypes') ? 'mm-active' : ''}}">سابقه
                                                        ضمانت نامه</a>
                                        </li>
                                        <li><a [routerLink]="['base/OwnerTypes']"
                                                        class="{{(currentHref == '/base/owner-types') ? 'mm-active' : ''}}">وضعیت
                                                        مالکیت</a>
                                        </li>
                                        <li><a [routerLink]="['base/StartupTypes']"
                                                        class="{{(currentHref == '/base/StartupTypes') ? 'mm-active' : ''}}">نوع
                                                        استارتاپ</a>
                                        </li>
                                        <li><a [routerLink]="['base/StockTypes']"
                                                        class="{{(currentHref == '/base/StockTypes') ? 'mm-active' : ''}}">وضعیت
                                                        بازار</a>
                                        </li>
                                        <li><a [routerLink]="['base/TotalAccountingTitleRanges']"
                                                        class="{{(currentHref == '/base/TotalAccountingTitleRanges') ? 'mm-active' : ''}}">بازه
                                                        سرفصل های محاسباتی</a>
                                        </li>
                                        <li><a [routerLink]="['base/TotalAccountingTitles']"
                                                        class="{{(currentHref == '/base/TotalAccountingTitles') ? 'mm-active' : ''}}">سرفصل
                                                        های محاسباتی</a>
                                        </li>
                                        <li><a [routerLink]="['base/VasigheTypes']"
                                                        class="{{(currentHref == '/base/VasigheTypes') ? 'mm-active' : ''}}">انواع
                                                        وسیقه</a>
                                        </li>
                                        <li><a [routerLink]="['base/ZaribTypes']"
                                                        class="{{(currentHref == '/base/ZaribTypes') ? 'mm-active' : ''}}">ضرایب</a>
                                        </li>
                                </ul>
                        </li>





                        <li [ngClass]="{'mm-active': authbaseArray.indexOf(currentHref) > -1}">
                                <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                        <i class="fa fa-adjust" aria-hidden="true"></i>
                                        <span class="nav-text">اطلاعات پایه کاربری</span>
                                </a>


                                <ul aria-expanded="false"
                                        [ngClass]="{'mm-show': authbaseArray.indexOf(currentHref) > -1}"
                                        style="z-index: 2000;">
                                        <li><a [routerLink]="['authbase/Softwares']"
                                                        class="{{(currentHref == '/authbase/Softwares') ? 'mm-active' : ''}}">
                                                        نرم افزار</a>
                                        </li>
                                        <li><a [routerLink]="['authbase/Components']"
                                                        class="{{(currentHref == '/authbase/Components') ? 'mm-active' : ''}}">
                                                        کامپوننتها</a>
                                        </li>
                                </ul>
                        </li>











                </ul>
                <div class="copyright">
                        <p>نسخه نرم افزار
                                {{appVersion}}
                        </p>
                </div>
        </div>
</div>