import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from './../../environments/environment';
import { CurrentUser } from '../_models';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const SetareganUser = localStorage.getItem('SetareganUser');
        if (SetareganUser !== null) {
            const currentUser = JSON.parse(SetareganUser) as CurrentUser;
            const isLoggedIn = currentUser && currentUser.access_token;
            const isODataUrl = request.url.startsWith(environment.odataUrl);
            const isApiUrl = request.url.startsWith(environment.apiUrl);
            if (isLoggedIn && (isApiUrl || isODataUrl)) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser.access_token}`
                    }
                });
            }
        }

        return next.handle(request);
    }
}
