import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { LangService } from './lang.service';

const languageKey = '__lang';

@Injectable({
    providedIn: 'root',
})
export class MessageService {

    constructor(
        private langService: LangService,
        private toastr: ToastrService,
    ) { }

    init(): void {
    }

    Success(messageKey: string, title: string = '', doTranslate: boolean = true, timeOut: number = 5000): void {
        let MessageShow: string = messageKey;
        if (doTranslate) {

            MessageShow = this.langService.TranslateKey(messageKey);
        }

        this.toastr.success(MessageShow, title, {
            timeOut: timeOut,
        });
    }

    Warning(messageKey: string, title: string = '', doTranslate: boolean = true, timeOut: number = 5000): void {
        let MessageShow: string = messageKey;
        if (doTranslate) {

            MessageShow = this.langService.TranslateKey(messageKey);
        }
        this.toastr.warning(MessageShow, title, {
            timeOut: timeOut,
        });
    }

    Error(messageKey: string, title: string = '', doTranslate: boolean = true, timeOut: number = 5000): void {
        let MessageShow: string = messageKey;
        if (doTranslate) {

            MessageShow = this.langService.TranslateKey(messageKey);
        }
        this.toastr.error(MessageShow, title, {
            timeOut: timeOut,
        });
    }

    Clear(): void {
        this.toastr.clear();
    }
}


