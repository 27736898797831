import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StartupPageComponent } from './startup-page/startup-page.component';
import { IndexComponent } from './dashboard/index/index.component';
import { NestableComponent } from './plugins/nestable/nestable.component';
import { RegisterComponent } from './pages/register/register.component';
import { LoginComponent } from './pages/login/login.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { Error400Component } from './pages/error400/error400.component';
import { Error403Component } from './pages/error403/error403.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { Error503Component } from './pages/error503/error503.component';

const routes: Routes = [
  { path: '', redirectTo: 'Config/entity/Users', pathMatch: 'full' },
  {
    path: 'Config', component: StartupPageComponent,
    children: [
      { path: '', component: IndexComponent },
      { path: 'index', component: IndexComponent },
      { path: 'base', loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule) },
      { path: 'bpmbase', loadChildren: () => import('./views/bpmbase/bpm-base.module').then(m => m.BPMBaseModule) },
      { path: 'humanresourcebase', loadChildren: () => import('./views/human-resource-base/human-resource-base.module').then(m => m.HumanResourceBaseModule) },
      { path: 'humanresource', loadChildren: () => import('./views/human-resource/human-resource.module').then(m => m.HumanResourceModule) },
      { path: 'auth', loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule) },
      { path: 'authbase', loadChildren: () => import('./views/authbase/authbase.module').then(m => m.AuthBaseModule) },
      { path: 'entity', loadChildren: () => import('./views/entity/entity.module').then(m => m.EntityModule) },

      //      { path: 'uc-nestable', component: NestableComponent },

    ]
  },


  { path: 'login', component: LoginComponent },
  { path: 'page-register', component: RegisterComponent },
  { path: 'page-lock-screen', component: LockScreenComponent },
  { path: 'page-forgot-password', component: ForgotPasswordComponent },
  { path: 'page-error-400', component: Error400Component },
  { path: 'page-error-403', component: Error403Component },
  { path: 'page-error-404', component: Error404Component },
  { path: 'page-error-500', component: Error500Component },
  { path: 'page-error-503', component: Error503Component },



  { path: '**', component: Error404Component },

  //{ path: 'timesheet', loadChildren: () => import('./views/timesheet/timesheet.module').then(m => m.timesheetModule) },
  //{ path: 'inventorybase', loadChildren: () => import('./views/inventorybase/inventorybase.module').then(m => m.inventorybaseModule) },
  //{ path: 'incubatorbase', loadChildren: () => import('./views/incubatorbase/incubatorbase.module').then(m => m.incubatorbaseModule) },
  //{ path: 'humanresource', loadChildren: () => import('./views/humanresource/humanresource.module').then(m => m.humanresourceModule) },
  //{ path: 'gp', loadChildren: () => import('./views/gp/gp.module').then(m => m.gpModule) },
  //{ path: 'gpdaily', loadChildren: () => import('./views/gpdaily/gpdaily.module').then(m => m.gpdailyModule) },
  //{ path: 'gpbase', loadChildren: () => import('./views/gpbase/gpbase.module').then(m => m.gpbaseModule) },
  //{ path: 'finalcost', loadChildren: () => import('./views/finalcost/finalcost.module').then(m => m.finalcostModule) },
  //{ path: 'finalcostbase', loadChildren: () => import('./views/finalcostbase/finalcostbase.module').then(m => m.finalcostbaseModule) },
  //{ path: 'budget', loadChildren: () => import('./views/budget/budget.module').then(m => m.budgetModule) },
  //{ path: 'budgetbase', loadChildren: () => import('./views/budgetbase/budgetbase.module').then(m => m.budgetbaseModule) },
  //{ path: 'auth', loadChildren: () => import('./views/auth/auth.module').then(m => m.authModule) },
  //{ path: 'authbase', loadChildren: () => import('./views/authbase/authbase.module').then(m => m.authbaseModule) },
  //{ path: 'accounting', loadChildren: () => import('./views/accounting/accounting.module').then(m => m.accountingModule) },
  //{ path: 'accountingbase', loadChildren: () => import('./views/accountingbase/accountingbase.module').then(m => m.accountingbaseModule) },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
