export class ChartInfo {
    Type: string;
    Label: string;
    Labels: string[];
    ChartDatas: ChartData[];
  }
  
  
  
  export class ChartData {
    Datas: number[];
    BackgroundColors: string[];
  }
  