export * from './Global.model';

//base-tabel
//export { LanguageDto , Combo_LanguageDto} from 'src/app/_models/base-tabel/language.model';

//base-tabel


//user-authentication
//export { UserDto , RegisterDto , CurrentUser ,  UserInfo , LoginDto , ForgetPasswordChangePasswordDto} from 'src/app/_models/user-authentication/user.model';
//user-authentication


export { hlpDate } from 'src/app/_models/general/general-class.model';
export { ChartData , ChartInfo } from 'src/app/_models/general/chart.model';
export { NavigationModel } from 'src/app/_models/general/NavigationModel.model';
export * from 'src/app/_models/general/user.model';




