import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { Injectable } from '@angular/core';
import { ErrorService } from 'src/app/shared/error.service';
import { LoginDto, UserInfo } from 'src/app/_models';


const serviceName = "User"


@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService
  ) {
  }

  ngOnInit(): void { }
  
  async login(_LoginDto: LoginDto): Promise<boolean> {
    let returnvalue= false;
    await this.RepositoryService.loginAsync(serviceName+'/Login/', _LoginDto)
      .then((res) => {
        localStorage.setItem('SetareganUser', JSON.stringify(res));
        returnvalue = true;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return false;
        });
    return returnvalue;
  }


  async logout(): Promise<boolean> {
    let returnvalue = false;
    await this.RepositoryService.logoutAsync(serviceName + '/logout/')
      .then((res) => {
        returnvalue = true;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return false;
        });
    return returnvalue;
  }


  async Get_UserInfo(): Promise<UserInfo> {
    let returnvalue = new UserInfo();
    await this.RepositoryService.getItemAsync('SetareganUser/Get_UserInfo/')
      .then((res) => {
        returnvalue = res as UserInfo;
        localStorage.setItem('SetareganUserInfo', JSON.stringify(res));
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnvalue;
  }
}
